import React from 'react';

export function LoadingState() {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="relative w-24 h-24 animate-spin">
        {/* Main hat body */}
        <div className="absolute w-16 h-20 bg-gradient-to-br from-red-500 to-red-600 rounded-t-[120px] transform -rotate-12">
          {/* White trim at bottom */}
          <div className="absolute bottom-0 left-0 right-0 h-4 bg-white rounded-lg" />
        </div>
        {/* Pom pom */}
        <div className="absolute -top-1 -right-1 w-8 h-8">
          <div className="absolute inset-0 bg-white rounded-full shadow-lg" />
          <div className="absolute inset-2 bg-gradient-to-br from-gray-100 to-white rounded-full" />
        </div>
      </div>
      <p className="mt-4 text-white text-lg">Loading holders data...</p>
    </div>
  );
}