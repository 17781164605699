import React, { useEffect, useRef, useState, useMemo } from 'react';

interface SnowflakeProps {
  holder: {
    address: string;
    balance: number;
  };
  index: number;
  viewportWidth: number;
  viewportHeight: number;
  isHighlighted?: boolean;
}

export function Fish({ holder, index, viewportWidth, viewportHeight, isHighlighted }: SnowflakeProps) {
  const [showTooltip, setShowTooltip] = useState(false);
  const fishRef = useRef<HTMLDivElement>(null);
  const [position] = useState({
    x: Math.random() * (viewportWidth - 100) + 50,
    y: Math.random() * (viewportHeight * 0.6) + (viewportHeight * 0.2)
  });
  const [direction] = useState(Math.random() > 0.5 ? 1 : -1);
  const speed = useRef({
    x: (Math.random() * 0.3 - 0.15),
    y: (Math.random() * 0.2 - 0.1)
  });
  const positionRef = useRef(position);

  useEffect(() => {
    let frameId: number;
    const animate = () => {
      const newX = positionRef.current.x + speed.current.x;
      const newY = positionRef.current.y + speed.current.y;

      // Boundary checks with padding
      const padding = 50;
      if (newX > viewportWidth - padding) {
        positionRef.current.x = viewportWidth - padding;
        speed.current.x *= -1;
      } else if (newX < padding) {
        positionRef.current.x = padding;
        speed.current.x *= -1;
      } else {
        positionRef.current.x = newX;
      }

      if (newY > viewportHeight * 0.8) {
        positionRef.current.y = viewportHeight * 0.8;
        speed.current.y *= -1;
      } else if (newY < viewportHeight * 0.2) {
        positionRef.current.y = viewportHeight * 0.2;
        speed.current.y *= -1;
      } else {
        positionRef.current.y = newY;
      }

      // Random direction changes
      if (Math.random() < 0.001) {
        speed.current.x = (Math.random() * 0.3 - 0.15);
      }

      if (fishRef.current) {
        fishRef.current.style.transform = `translate(${positionRef.current.x}px, ${positionRef.current.y}px)`;
      }

      frameId = requestAnimationFrame(animate);
    };

    frameId = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(frameId);
  }, [viewportWidth, viewportHeight]);

  const isLarge = index < 10;
  const size = isLarge ? 'large' :
              holder.balance > 10000 ? 'large' :
              holder.balance > 5000 ? 'medium' :
              holder.balance > 1000 ? 'medium' :
              holder.balance > 500 ? 'small' :
              holder.balance > 100 ? 'small' : 'tiny';
              
  const sizeClasses = {
    large: 'text-5xl',
    medium: 'text-4xl',
    small: 'text-3xl',
    tiny: 'text-2xl'
  };

  const colors = [
    'text-white',
    'text-blue-100',
    'text-blue-50',
    'text-white',
    'text-blue-100',
    'text-white',
    'text-blue-50',
    'text-white'
  ];

  const color = isLarge ? colors[0] : colors[(index + 1) % colors.length];

  const snowflakeShape = useMemo(() => {
    const shapes = ['❄', '❅', '❆'];
    return shapes[index % shapes.length];
  }, [index]);

  const animationDelay = useMemo(() => {
    return `${Math.random() * -4}s`;
  }, []);

  const rotationAmount = useMemo(() => {
    return Math.random() * 360;
  }, []);

  return (
    <div
      ref={fishRef}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      className={`fixed cursor-pointer ${sizeClasses[size]} ${color} will-change-transform transition-all duration-500 hover:scale-125`}
      style={{
        transform: `translate(${position.x}px, ${position.y}px)`,
        filter: isHighlighted ? 'drop-shadow(0 0 8px rgba(255, 255, 255, 1)) drop-shadow(0 0 12px rgba(255, 255, 255, 0.8))' : 'drop-shadow(0 0 4px rgba(255, 255, 255, 0.8))',
        animationDelay,
        opacity: 1
      }}
    >
      <div 
        className="animate-snowfall"
        style={{
          transform: `rotate(${rotationAmount}deg)`,
          animation: `snowfall 3s ease-in-out infinite, sway 4s ease-in-out infinite`
        }}
      >
        {snowflakeShape}
      </div>

      {/* Tooltip - Now outside the fish-body div to prevent flipping */}
      {showTooltip && (
        <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 bg-black/90 backdrop-blur-sm rounded-lg p-3 shadow-xl border border-white/10 z-50 pointer-events-none">
          <div className="text-white space-y-2">
            <div>
              <div className="text-white/60 text-xs">Wallet</div>
              <div className="font-mono text-xs truncate">{holder.address}</div>
            </div>
            <div>
              <div className="text-white/60 text-xs">Balance</div>
              <div className="font-mono text-sm">{holder.balance.toLocaleString()}</div>
            </div>
            <div className="text-xs text-blue-400">
              #{index + 1} Holder
            </div>
          </div>
          <div className="absolute left-1/2 -bottom-1 w-2 h-2 bg-black/90 -translate-x-1/2 rotate-45 border-r border-b border-white/10"></div>
        </div>
      )}
    </div>
  );
}