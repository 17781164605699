import { Connection, PublicKey, ParsedAccountData } from '@solana/web3.js';

export interface TokenHolder {
  address: string;
  balance: number;
}

export interface TokenInfo {
  holders: TokenHolder[];
  symbol: string;
}

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const RPC_ENDPOINT = 'https://mainnet.helius-rpc.com/?api-key=a9facbd0-670d-430b-827b-c80dff2f7092';

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

async function fetchWithTimeout(url: string, options: RequestInit = {}, timeout = 10000) {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  try {
    const response = await fetch(url, { ...options, signal: controller.signal });
    clearTimeout(id);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    clearTimeout(id);
    throw error;
  }
}

async function getTokenMetadata(mintAddress: string, connection: Connection): Promise<{ symbol: string }> {
  try {
    const heliusResponse = await fetchWithTimeout(
      RPC_ENDPOINT,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          jsonrpc: '2.0',
          id: 'metadata',
          method: 'getTokenMetadata',
          params: { mint: mintAddress }
        })
      }
    );
    const heliusData = await heliusResponse.json();
    const symbol = heliusData.result?.symbol || '';

    if (symbol) {
      return { symbol: symbol.toUpperCase() };
    }
  } catch (error) {
    console.warn('Helius metadata fetch failed:', error);
  }

  try {
    const mint = new PublicKey(mintAddress);
    const mintInfo = await connection.getParsedAccountInfo(mint);
    const symbol = (mintInfo.value?.data as any)?.parsed?.info?.symbol || 'UNKNOWN';
    return { symbol: symbol.toUpperCase() };
  } catch (error) {
    console.warn('Final token info fetch failed:', error);
    return { symbol: 'UNKNOWN' };
  }
}

export async function getTokenHolders(mintAddress: string): Promise<TokenInfo> {
  let retries = 0;
  
  while (retries < MAX_RETRIES) {
    try {
      if (!mintAddress) {
        throw new Error('Invalid mint address');
      }

      const connection = new Connection(RPC_ENDPOINT, {
        commitment: 'confirmed',
        confirmTransactionInitialTimeout: 60000
      });

      const mint = new PublicKey(mintAddress);

      const mintAccount = await connection.getAccountInfo(mint);
      if (!mintAccount) {
        throw new Error('Token mint account not found');
      }

      const accounts = await connection.getParsedProgramAccounts(
        new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'),
        {
          filters: [
            {
              dataSize: 165,
            },
            {
              memcmp: {
                offset: 0,
                bytes: mint.toBase58(),
              },
            },
          ],
        }
      );

      if (!accounts || accounts.length === 0) {
        throw new Error('No token holders found');
      }

      const holders = accounts
        .map(({ account, pubkey }) => {
          const parsedData = account.data as ParsedAccountData;
          return {
            address: pubkey.toString(),
            balance: parsedData.parsed?.info?.tokenAmount?.uiAmount || 0,
          };
        })
        .filter(holder => holder.balance > 0)
        .sort((a, b) => b.balance - a.balance);

      if (holders.length === 0) {
        throw new Error('No active token holders found');
      }

      const metadata = await getTokenMetadata(mintAddress, connection);

      return {
        holders,
        symbol: metadata.symbol
      };

    } catch (error) {
      retries++;
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      console.warn(`Attempt ${retries} failed: ${errorMessage}`);
      
      if (retries === MAX_RETRIES) {
        throw new Error(`Failed to fetch token holders: ${errorMessage}`);
      }
      
      await delay(RETRY_DELAY * retries);
    }
  }

  throw new Error('Failed to fetch token holders');
}