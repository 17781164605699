import React, { useEffect, useState, useRef } from 'react';
import { Fish } from './Fish';
import { Snowflake, ZoomIn, ZoomOut, Move, Twitter, MessageCircle } from 'lucide-react';
import { getTokenHolders, TokenInfo } from '../utils/solana';
import { LoadingState } from './LoadingState';
import { SearchBar } from './SearchBar';
import { SnowOverlay } from './SnowOverlay';
import { HoldersList } from './HoldersList';

const INITIAL_TOKEN_INFO: TokenInfo = {
  holders: [],
  symbol: ''
};

export function Aquarium() {
  const containerRef = useRef<HTMLDivElement>(null);
  const [tokenInfo, setTokenInfo] = useState<TokenInfo>(INITIAL_TOKEN_INFO);
  const [loading, setLoading] = useState(false);
  const [scale, setScale] = useState(0.75);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth * 2.5);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight * 2.5);
  const [highlightedAddress, setHighlightedAddress] = useState<string | null>(null);
  const [currentAddress, setCurrentAddress] = useState('');

  const fetchData = async (address: string) => {
    setLoading(true);
    try {
      const data = await getTokenHolders(address);
      setTokenInfo(data);
      setCurrentAddress(address);
    } catch (error) {
      console.error('Error fetching token holders:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleHighlightHolder = (address: string) => {
    setHighlightedAddress(address);
    setTimeout(() => setHighlightedAddress(null), 5000);
  };

  const handleReset = () => {
    setTokenInfo(INITIAL_TOKEN_INFO);
  };

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth * 2.5);
      setViewportHeight(window.innerHeight * 2.5);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setDragStart({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    
    setPosition({
      x: e.clientX - dragStart.x,
      y: e.clientY - dragStart.y
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleWheel = (e: React.WheelEvent) => {
    e.preventDefault();
    const delta = e.deltaY * -0.001;
    setScale(s => Math.min(Math.max(0.3, s + delta), 2));
  };

  if (loading) {
    return <LoadingState />;
  }

  const showWelcomeState = !loading && tokenInfo.holders.length === 0;

  return (
    <div 
      ref={containerRef}
      className="fixed inset-0 overflow-hidden bg-gradient-to-b from-red-900 via-red-950 to-red-950"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onWheel={handleWheel}
      style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
    >
      {/* Background Elements */}
      <div className="absolute inset-0 z-0">
        {/* Light rays effect */}
        <div className="absolute inset-0 overflow-hidden">
          {[...Array(8)].map((_, i) => (
            <div
              key={i}
              className="absolute w-1 h-screen bg-red-400/10 blur-[100px]"
              style={{
                left: `${(i + 1) * 12.5}%`,
                transform: 'rotate(-45deg)',
                animation: `rays ${3 + i}s infinite alternate ease-in-out`,
              }}
            />
          ))}
        </div>

        {/* Aquarium Content */}
        <div 
          className="relative w-full h-full transform-gpu"
          style={{ 
            transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
            width: viewportWidth,
            height: viewportHeight,
            left: -viewportWidth / 4,
            top: -viewportHeight / 4
          }}
        >
          {/* Fish */}
          {showWelcomeState ? (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
              <h2 className="text-4xl font-bold text-white mb-4">Welcome to SnowflakeAI</h2>
              <p className="text-xl text-white/70">
                Enter any Solana token address above to visualize its holders
              </p>
            </div>
          ) : (
            tokenInfo.holders.map((holder, index) => (
            <Fish
              key={holder.address}
              holder={holder}
              index={index}
              viewportWidth={viewportWidth}
              viewportHeight={viewportHeight}
              isHighlighted={holder.address === highlightedAddress}
            />
          )))}
        </div>
      </div>

      {/* Overlay Elements */}
      <SnowOverlay />

      {/* UI Controls - Highest z-index */}
      <div className="fixed top-4 left-4 right-4 z-50 flex flex-col gap-4 pointer-events-none">
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-2">
            <button
              onClick={handleReset}
              className="flex items-center gap-2 bg-black/20 hover:bg-black/30 backdrop-blur-sm p-3 rounded-lg pointer-events-auto transition-colors group"
            >
              <Snowflake className="h-6 w-6 text-white animate-spin-slow" />
              <h1 className="text-xl font-bold text-white group-hover:text-blue-200 transition-colors">
                SnowflakeAI
              </h1>
            </button>
            <div className="flex items-center gap-2 px-3">
              <a
                href="https://x.com/SnowflakeAI_"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 bg-black/20 hover:bg-black/40 backdrop-blur-sm px-3 py-2 rounded-lg text-white/70 hover:text-white transition-all duration-200 pointer-events-auto group"
              >
                <Twitter className="h-4 w-4" />
                <span className="text-sm font-medium opacity-0 group-hover:opacity-100 -ml-1 group-hover:ml-0 transition-all duration-200">
                  Twitter
                </span>
              </a>
              <a
                href="https://t.me/Snowflake_AI"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 bg-black/20 hover:bg-black/40 backdrop-blur-sm px-3 py-2 rounded-lg text-white/70 hover:text-white transition-all duration-200 pointer-events-auto group"
              >
                <MessageCircle className="h-4 w-4" />
                <span className="text-sm font-medium opacity-0 group-hover:opacity-100 -ml-1 group-hover:ml-0 transition-all duration-200">
                  Telegram
                </span>
              </a>
            </div>
          </div>
          <div className="pointer-events-auto">
            <SearchBar onSearch={fetchData} isLoading={loading} currentAddress={currentAddress} />
          </div>
          <div className="flex items-center gap-4 bg-black/20 backdrop-blur-sm p-3 rounded-lg pointer-events-auto">
            <Move className="h-5 w-5 text-white/70" />
            <div className="w-px h-6 bg-white/20" />
            <button
              onClick={() => setScale(s => Math.max(0.3, s - 0.1))}
              className="text-white hover:text-blue-200 transition-colors"
            >
              <ZoomOut className="h-6 w-6" />
            </button>
            <span className="text-white font-medium">
              {Math.round(scale * 100)}%
            </span>
            <button
              onClick={() => setScale(s => Math.min(2, s + 0.1))}
              className="text-white hover:text-blue-200 transition-colors"
            >
              <ZoomIn className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      {/* Holders List - High z-index */}
      {!showWelcomeState && (
        <div className="z-40">
          <HoldersList holders={tokenInfo.holders} onHighlightHolder={handleHighlightHolder} />
        </div>
      )}
    </div>
  );
}