import React, { useState, useMemo } from 'react';
import { Trophy, Search, ChevronRight, ChevronLeft } from 'lucide-react';
import { TokenHolder } from '../utils/solana';

interface HoldersListProps {
  holders: TokenHolder[];
  onHighlightHolder: (address: string) => void;
}

export function HoldersList({ holders, onHighlightHolder }: HoldersListProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [isMinimized, setIsMinimized] = useState(false);
  
  const filteredHolders = useMemo(() => {
    if (!searchQuery.trim()) return holders;
    
    const query = searchQuery.toLowerCase();
    return holders.filter(holder => 
      holder.address.toLowerCase().includes(query)
    );
  }, [holders, searchQuery]);

  const getHolderRank = (address: string) => {
    return holders.findIndex(h => h.address.toLowerCase() === address.toLowerCase()) + 1;
  };

  return (
    <div 
      className={`fixed right-4 top-24 bottom-4 transition-all duration-300 ease-in-out ${
        isMinimized ? 'w-12' : 'w-80'
      } bg-black/80 backdrop-blur-sm rounded-lg border border-white/10 overflow-hidden flex flex-col`}
    >
      <button
        onClick={() => setIsMinimized(!isMinimized)}
        className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-1/2 w-8 h-8 bg-black/80 backdrop-blur-sm rounded-full border border-white/10 flex items-center justify-center text-white/70 hover:text-white transition-colors z-10"
      >
        {isMinimized ? <ChevronLeft className="w-5 h-5" /> : <ChevronRight className="w-5 h-5" />}
      </button>

      {!isMinimized && (
        <>
          <div className="p-4 border-b border-white/10">
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search your wallet address..."
                className="w-full bg-white/10 text-white placeholder-white/50 px-4 py-2 pr-10 rounded-lg border border-white/10 focus:outline-none focus:border-blue-400 transition-colors text-sm"
              />
              <Search className="absolute right-3 top-1/2 -translate-y-1/2 w-4 h-4 text-white/50" />
            </div>
          </div>
          <div className="flex-1 overflow-y-auto custom-scrollbar">
            {filteredHolders.map((holder, index) => {
              const actualRank = getHolderRank(holder.address);
              const isSearchResult = searchQuery && holder.address.toLowerCase().includes(searchQuery.toLowerCase());
              
              return (
                <div
                  key={holder.address}
                  className={`p-4 border-b border-white/5 transition-colors cursor-pointer ${
                    isSearchResult ? 'bg-blue-500/20' : 'hover:bg-white/5'
                  }`}
                  onClick={() => onHighlightHolder(holder.address)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      <span className="text-sm font-bold text-white/70">#{actualRank}</span>
                      <div>
                        <div className="text-xs text-white/50 font-mono truncate w-32">
                          {holder.address}
                        </div>
                        <div className="text-sm text-white font-medium">
                          {holder.balance.toLocaleString()} tokens
                        </div>
                      </div>
                    </div>
                    {actualRank <= 3 && (
                      <div className={`
                        w-6 h-6 rounded-full flex items-center justify-center
                        ${actualRank === 1 ? 'bg-yellow-500' : 
                          actualRank === 2 ? 'bg-gray-400' : 
                          'bg-amber-700'}
                      `}>
                        <Trophy className="w-3 h-3 text-white" />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}

      {isMinimized && (
        <div className="flex-1 flex items-center justify-center">
          <div className="transform -rotate-90 whitespace-nowrap text-white/70 font-medium tracking-wider">
            Holders List
          </div>
        </div>
      )}
    </div>
  );
}