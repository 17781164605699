import React, { useState } from 'react';
import { Search, RefreshCw } from 'lucide-react';

interface SearchBarProps {
  onSearch: (address: string) => void;
  isLoading: boolean;
  currentAddress?: string;
}

export function SearchBar({ onSearch, isLoading, currentAddress }: SearchBarProps) {
  const [address, setAddress] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (address.trim()) {
      onSearch(address.trim());
    }
  };

  const handleRefresh = () => {
    if (currentAddress) {
      onSearch(currentAddress);
    }
  };

  return (
    <div className="flex gap-2">
      <form onSubmit={handleSubmit} className="w-96">
        <div className="relative">
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Enter Solana token address..."
            className="w-full bg-black/20 backdrop-blur-sm text-white placeholder-white/50 px-4 py-2 pr-12 rounded-lg border border-white/10 focus:outline-none focus:border-blue-400 transition-colors"
            disabled={isLoading}
          />
          <button
            type="submit"
            disabled={isLoading || !address.trim()}
            className="absolute right-2 top-1/2 -translate-y-1/2 text-white/70 hover:text-white disabled:text-white/30 transition-colors"
          >
            <Search className="w-5 h-5" />
          </button>
        </div>
      </form>
      <button
        onClick={handleRefresh}
        disabled={isLoading}
        className="bg-black/20 backdrop-blur-sm text-white/70 hover:text-white disabled:text-white/30 px-3 rounded-lg border border-white/10 transition-colors flex items-center justify-center"
      >
        <RefreshCw className={`w-5 h-5 ${isLoading ? 'animate-spin' : ''}`} />
      </button>
    </div>
  );
}